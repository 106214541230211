import React, { useState, useEffect } from 'react';

import { APP_URL, withCredentials } from '../App';
import axios from 'axios';
import { decryptResponse } from './CryptoUtil';
//import Modal from 'react-modal'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { encryptPayload } from './CryptoUtil';





const User_Management = () => {
    const [users, setUsers] = useState([]);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [emailAccount, setEmailAccount] = useState('');
    const [name, setName] = useState('');
   
     const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    // Fetch all users from the backend on component mount
    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = async ()=>{
        try{
            const response = await axios.get(APP_URL+'getUsers.php',
             
              {
                withCredentials: withCredentials,
                headers: {
                'Content-Type': 'application/json',
                'X-API-Key': localStorage.getItem('token')
              }})
           
            const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
            setUsers(decryptText.items);
            }catch(error){
              alert("Cannot fetch the data now, please try again")
            }
    }

    // Toggle login status with confirmation
    const toggleLoginStatus = async (email) => {
        const confirmToggle = window.confirm(`Are you sure you want to Logout this user?` + email);
        if (confirmToggle) {
            axios.post(APP_URL+"updateLoginStatus.php", { "email":email}, {
                withCredentials: true,
                headers: {
                'Content-Type': 'application/json',
                'X-API-Key': localStorage.getItem('token')
              }})
            .then(response => {

                users.map(user =>

                   console.log(user))

               setUsers(users.map(user =>

                user.email === email ? { ...user, loginstatus: "LOGOUT" } : user
               
              ));
            })
            .catch(error => {
              alert(error)
            });
            
        }
    };

    // Toggle user status with confirmation
    const toggleUserStatus = async (user,email) => {
        const confirmToggle = window.confirm(`Are you sure you want to ${user.userstatus==="ACTIVE" ? 'disable' : 'enable'} this user? `+email);
        if (confirmToggle) {
            let userstatus ="ACTIVE";
            if(user.userstatus==="ACTIVE"){
                userstatus= "INACTIVE"

            }
            axios.post(APP_URL+"updateUserStatus.php", { "email":email,"status":userstatus}, {
                withCredentials: true,
                headers: {
                'Content-Type': 'application/json',
                'X-API-Key': localStorage.getItem('token')
              }})
            .then(response => {

                users.map(user =>

                   console.log(user))

               setUsers(users.map(user =>

                user.email === email ? { ...user, userstatus: userstatus } : user
               
              ));
            })
            .catch(error => {
              alert(error)
            });
        }
    };





   

    // Handle Add User Form Submission
    const handleAddUserSubmit = async (event) => {
        event.preventDefault();
        if(!emailAccount.endsWith('@hdbfs.com')){
            alert("Only hdbfs email accounts are valid");
            return 

        }
        const phoneRegex = /^[0-9]{10}$/; // Matches exactly 10 digits
        if(!phoneRegex.test(phone)){
            alert("Enter a valid 10 digits number");
            return 

        }
       

        try {
            console.log({ "email":emailAccount,"name": name ,"phone":phone,"password":password});

            const data= encryptPayload({ "email":emailAccount,"name": name ,"phone":phone,"password":password})
            console.log(data)
            
     
          } catch (error) {
            alert("Please try after sometime")
          }

       
    };


    return (
        <div>
            <>
      

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
     
                    <form onSubmit={handleAddUserSubmit}>
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" name="name" onChange={(e) => setName(e.target.value)} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="email" onChange={(e) => setEmailAccount(e.target.value)} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <input type="text" name="phone" onChange={(e) => setPhone(e.target.value)} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} className="form-control" required />
                        </div>
                        <button type="submit" className="btn btn-primary">Add User</button>
                    </form>
             
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>




            <div className="row mb-2" style={{marginBottom:"40px"}}>
                <h5 className="col-9">All User Details</h5>
                <button className="col-3 btn btn-block btn-primary btn-sm" onClick={handleShow}>Add User</button>
            </div>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Login Status</th>
                                            <th>User Status</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user) => (
                                            <tr key={user.id}>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.phone}</td>
                                                <td>
                                                    <p
                                                       
                                                        className={`text-${user.loginstatus ==="LOGIN" ? 'success' : 'danger'} `}
                                                        onClick={() => toggleLoginStatus(user.email)}
                                                    >
                                                        {user.loginstatus} {user.loginstatus ==="LOGOUT"?<></>: 
                                                        <span style={{marginLeft:"10px"}}>

                                                         <img src='/assets/images/log-out.svg'  alt="Logout" title='logout User' />
                                                         </span>
                                                        }
                                                        
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        
                                                        className={`text-${user.userstatus==="ACTIVE" ? 'primary' : 'secondary'} `}
                                                       
                                                    >
                                                        {user.userstatus}
                                                        <span style={{marginLeft:"10px"}}>
                                                        {user.userstatus==="ACTIVE" ?
                                                            <img  onClick={() => toggleUserStatus(user,user.email)} src='/assets/images/user_x.svg' height="16px" width="16px" alt="Click to Disable User" title='Disable User' />:
                                                            <img  onClick={() => toggleUserStatus(user,user.email)} src='/assets/images/user_check.svg' height="16px" width="16px" alt="Click to Enable User" title='Enable User' />
                                                        }
                                                       
                                                        </span>

                                                        
                                                    </p>
                                                </td>
                                                <td>
                                                   
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
            


            
        </div>
    );
};

export default User_Management;