import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { APP_URL } from '../App';
import { encryptPayload ,decryptResponse} from './CryptoUtil';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Ensure the token is available in localStorage
    const token = "3ff1b8c52273486f8a4cdca2c178f4a7";//localStorage.getItem('resetToken');
    if (!token) {
      setMessage("Invalid or missing token. Please request a new reset link.");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('resetToken');

    if (!token) {
      setMessage("Token is missing or invalid.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    const data= encryptPayload(
        { 
            "token":token,
            "newPassword":newPassword,         

        })

    
   
    try {
      const response = await axios.post(APP_URL+'reset_password.php', data,
        {    
          headers:{
          'Content-Type':'Application/json'
          }
      });

      const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
     

      if (decryptText.status === 'success') { 
      
        setMessage('Password reset successful. Redirecting to login...');
        setTimeout(() => {
          localStorage.removeItem('resetToken');
          navigate('/login');
        }, 3000);
      } else {
        setMessage(decryptText.message);
      }
    } catch (error) {
      
      setMessage('An error occurred. Please try again.'+error);
    }
  };

  return (
    <div className="container-scroller">
    <div className="container-fluid page-body-wrapper">
           
    <div className="row w-100 mx-0">
     <div className="col-lg-4 mx-auto">
       <div className="auth-form-light text-left py-5 px-4 px-sm-5">
      <h2>Reset Your Password</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>New Password</label>
          <input
            type="password"
            className="form-control"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Reset Password
        </button>
      </form>
    </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default ResetPassword;