// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const CurrentDateTime = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const navigate = useNavigate();


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
      const now = new Date().getTime();
      
      const dateStr = localStorage.getItem("expiry")

      // Convert to a JavaScript Date object
      const dateObj = new Date(dateStr);

      // Get the Unix timestamp (seconds since epoch)
      const unixTime =dateObj.getTime();// Math.floor(dateObj.getTime() / 1000);
      

        if (now >= unixTime) {
        
          localStorage.removeItem("token");
          localStorage.removeItem("expiry");
          navigate("/login"); // Redirect to login page
        }

    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  const formattedDate = currentDateTime.toLocaleDateString();
  const formattedTime = currentDateTime.toLocaleTimeString();

  return (
    <div style={{'float':'right','marginLeft':'20px'}}>
         Today's Date: {formattedDate} &nbsp;  Time : {formattedTime}   
     
    </div>
  );
};

export default CurrentDateTime;
