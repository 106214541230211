// eslint-disable-next-line no-unused-vars
import React, { useState ,useEffect} from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { encryptPayload,decryptResponse } from './CryptoUtil';



import { NavLink, useNavigate } from "react-router-dom";

const Login = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    // Remove pre-login cookies
    Cookies.remove('sessionToken');
  }, []);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {

      const data= encryptPayload({ "email":phone,"password": password })
     // console.log(data)
      

 
     
    const response = await axios.post('https://www.screenbros.co/webapi/login.php',
    data,
    {headers:{
      'Content-Type':'Application/json'
    }})
    
    const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
     
      if (decryptText.status === 'success') {
        localStorage.setItem('loggedin', true);
        localStorage.setItem('token', decryptText.token);   
        localStorage.setItem('expiry', decryptText.expiry);
        localStorage.setItem('userlevel', decryptText.userlevel);
        console.log(decryptText.userlevel);
        localStorage.setItem('user', phone);               
        
        
        navigate("/");
        
      } else {
        
        alert(decryptText.message);
      }
    } catch (error) {
      alert("Please try after sometime")
    }
  };




    return (
<div>

 
    <div className="container-scroller">
   <div className="container-fluid page-body-wrapper">
          
   <div className="row w-100 mx-0">
    <div className="col-lg-4 mx-auto">
      <div className="auth-form-light text-left py-5 px-4 px-sm-5">
        <div className="brand-logo">
        
        
        </div>
       
        <h5 className="font-weight-light">Login to continue.</h5>
        <form onSubmit={handleSubmit}className="pt-3">
          <div className="form-group">
            <input type="text" name="phone" className="form-control form-control-lg" 
             value={phone} onChange={(e) => setPhone(e.target.value)} 
             placeholder="Enter Email or Phone" required  />
          </div>
        
          <div className="form-group">
            <input type="password" name="password" className="form-control form-control-lg"  
            value={password} onChange={(e) => setPassword(e.target.value)}
             placeholder=" Enter Your Password" required  />
          </div>
          <div className="mt-3 d-grid gap-2">
            <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">Log In</button>
          </div>
          <div className="my-2 d-flex justify-content-between align-items-center">
         
            <NavLink to="/forgotPassword" class="auth-link text-black">Forgot password?</NavLink>
            
           
           
          </div>
        
        </form>
      </div>
    </div>
    </div>
</div>
</div>
</div>
  );
};

export default Login;