import React, { Component } from 'react'

import CurrentDateTime from './CurrentDateTime';

export default class Header extends Component {
  render() {
    return (

      <div>
  <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
      <p>ScreenBros</p>
      
    </div>
    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <button className="navbar-toggler sidebar-hidden navbar-toggler align-self-center" type="button" data-toggle="minimize">
        <span className="icon-menu" />
      </button>
      <ul className="navbar-nav mr-lg-2">
        <li className="nav-item nav-search d-none d-lg-block">
          <div className="input-group">
            <div className="input-group-prepend hover-cursor" id="navbar-search-icon">
             
            </div>
           
            <p style={{'color':'red'}}>Dear User your login access Expires on {localStorage.getItem("expiry")} . Login again </p> 
            <i className="mdi mdi-calendar" /> 
                <div> <CurrentDateTime/> </div>
          </div>
        </li>
      </ul>
      <ul className="navbar-nav navbar-nav-right">
        <li className="nav-item dropdown">
         
        </li>
        <li className="nav-item nav-profile dropdown">
          
        </li>
        
      </ul>
      <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
        <span className="icon-menu" />
      </button>
    </div>
  </nav>
  
  </div>

    
        

    );
  }
}
